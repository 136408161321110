@font-face {
  font-family: 'JioType';
  src: local('JioType'), url(../assets/fonts/JioType-Light.ttf) format('ttf');
  font-display: swap;
}

@font-face {
  font-family: 'JioType';
  src: url(../assets/fonts/JioType-Medium.ttf);
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'JioType';
  src: url(../assets/fonts/JioType-Medium.ttf);
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'JioType';
  src: url(../assets/fonts/JioType-Bold.ttf);
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: 'JioType';
  src: url(../assets/fonts/JioType-Black.ttf);
  font-weight: 900;
  font-display: swap;
}
