body {
  margin: 0;
  padding: 0 !important;
  font-family: 'JioType' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  background-color: white;
}

* {
  font-family: 'JioType' !important;
}

html,
body,
#root,
#app > div {
  background-color: var(--color-primary-grey-20) !important;
}
