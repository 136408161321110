@import "./colors";
@import "./typography";

a {
  text-decoration: none;
}

//default unit for width is percentage

$unit: (
  rem: "rem",
  per: "%",
  px: "px",
);
$position: (
  top: "t",
  bottom: "b",
  left: "l",
  right: "r",
);

@function str-concat($string, $symbol) {
  @return $string + $symbol;
}

@for $size from 0 through 100 {
  @each $unit, $ut in $unit {
    @each $position, $pos in $position {
      .m-#{$pos}-#{$size} {
        margin-#{$position}: #{$size}px !important;
      }
      .m-#{$pos}-#{$size}-#{$unit} {
        margin-#{$position}: #{$size}#{$ut} !important;
      }

      .p-#{$pos}-#{$size} {
        padding-#{$position}: #{$size}px !important;
      }

      .p-#{$pos}-#{$size}-#{$unit} {
        padding-#{$position}: #{$size}#{$ut} !important;
      }
    }

    .m-#{$size}-#{$unit} {
      margin: #{$size}#{$ut} !important;
    }
    .mt-#{$size}-#{$unit} {
      margin-top: #{$size}#{$ut} !important;
    }
    .mb-#{$size}-#{$unit} {
      margin-bottom: #{$size}#{$ut} !important;
    }

    .ml-#{$size}-#{$unit} {
      margin-left: #{$size}#{$ut} !important;
    }

    .mr-#{$size}-#{$unit} {
      margin-right: #{$size}#{$ut} !important;
    }

    .p-#{$size}-#{$unit} {
      padding: #{$size}#{$ut} !important;
    }
    .pt-#{$size}-#{$unit} {
      padding-top: #{$size}#{$ut} !important;
    }

    .pb-#{$size}-#{$unit} {
      padding-bottom: #{$size}#{$ut} !important;
    }

    .pl-#{$size}-#{$unit} {
      padding-left: #{$size}#{$ut} !important;
    }

    .pr-#{$size}-#{$unit} {
      padding-right: #{$size}#{$ut} !important;
    }
  }

  .m-#{$size} {
    margin: #{$size}px !important;
  }
  .mt-#{$size} {
    margin-top: #{$size}px !important;
  }
  .mb-#{$size} {
    margin-bottom: #{$size}px !important;
  }
  .p-#{$size} {
    padding: #{$size}px !important;
  }
  .pt-#{$size} {
    padding-top: #{$size}px !important;
  }
  .pr-#{$size} {
    padding-right: #{$size}px !important;
  }
  .pl-#{$size} {
    padding-left: #{$size}px !important;
  }
  .pb-#{$size} {
    padding-bottom: #{$size}px !important;
  }
  .mr-#{$size} {
    margin-right: #{$size}px !important;
  }
  .ml-#{$size} {
    margin-left: #{$size}px !important;
  }
  .w-#{$size} {
    width: #{str-concat($size, "%")} !important;
  }
  .h-#{$size} {
    height: #{str-concat($size, "%")} !important;
  }
  .w-#{$size}-vw {
    width: #{str-concat($size, "vw")} !important;
  }
  .h-#{$size}-vh {
    height: #{str-concat($size, "vh")} !important;
  }
  .br-#{$size} {
    border-radius: #{$size}px !important;
  }
  .br-tl-#{$size} {
    border-top-left-radius: #{$size}px !important;
  }
  .br-tr-#{$size} {
    border-top-right-radius: #{$size}px !important;
  }
  .br-bl-#{$size} {
    border-bottom-left-radius: #{$size}px !important;
  }
  .br-br-#{$size} {
    border-bottom-right-radius: #{$size}px !important;
  }
}

.d-flex {
  display: flex !important;
}
.justify-between {
  display: flex !important;
  justify-content: space-between !important;
}
.justify-center {
  display: flex !important;
  justify-content: center !important;
}
.justify-start {
  display: flex !important;
  justify-content: flex-start !important;
}
.justify-end {
  display: flex !important;
  justify-content: flex-end !important;
}
.align-center {
  display: flex !important;
  align-items: center !important;
}
.align-start {
  display: flex !important;
  align-items: flex-start !important;
}
.align-end {
  display: flex !important;
  align-items: flex-end !important;
}
.align-baseline {
  display: flex !important;
  align-items: baseline !important;
}

.pos-rel {
  position: relative !important;
}
.pos-abs {
  position: absolute !important;
}
.disabled {
  opacity: 0.5;
  pointer-events: none !important;
}

.flex-row {
  display: flex !important;
  flex-direction: row !important;
}

.flex-column {
  display: flex !important;
  flex-direction: column !important;
}

.flex-center-center {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.d-none {
  display: none !important;
}

.pos-abs-center {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.font-weight-100 {
  font-weight: 100 !important;
}
.font-weight-200 {
  font-weight: 200 !important;
}
.font-weight-300 {
  font-weight: 300 !important;
}
.font-weight-400 {
  font-weight: 400 !important;
}
.font-weight-500 {
  font-weight: 500 !important;
}
.font-weight-600 {
  font-weight: 600 !important;
}
.font-weight-700 {
  font-weight: 700 !important;
}
.font-weight-800 {
  font-weight: 800 !important;
}
.font-weight-900 {
  font-weight: 900 !important;
}
.text-align-center {
  text-align: center !important;
}
.text-align-left {
  text-align: left !important;
}
.text-align-right {
  text-align: right !important;
}

.cursor-pointer {
  cursor: pointer;
}

// examples
//class m-20 (margin:20px)
//class p-20 (padding:20px)
//class w-20 (width:20%)
//class  m-t-10-px (margin-top:10px)
//class  m-b-20-per (margin-bottom:20%)
//class  ml-20-px (margin-left: 20px)

//class p-t-px ,pt-5-px, p-b-30-px
//class w-50-per w-10-per
