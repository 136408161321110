:root {
  --color-primary-grey-background: #dedfe2;
  --color-primary-20: #e8e8fc;
  --color-primary-30: #9999ff;
  --color-primary-40: #6464ff;
  --color-primary-50: #3535f3;
  --color-primary-60: #000093;
  --color-primary-light: #0f3cc9;
  --color-primary-70: #00004c;
  --color-primary-80: #010029;
  --color-primary-bg: rgba(255, 255, 255, 0.55);
  --color-primary-inverse: #ffffff;
  --color-primary-background: #ffffff;
  --color-primary-grey-100: #141414;
  --color-primary-grey-90: #c4c2c2;
  --color-primary-grey-80: rgba(0, 0, 0, 0.65);
  --color-primary-grey-60: #b5b5b5;
  --color-primary-grey-40: #e0e0e0;
  --color-primary-grey-30: rgba(0, 0, 0, 0.24);
  --color-primary-grey-20: #f5f5f5;
  --color-primary-grey-border: rgb(197, 192, 192);
  --color-primary-white-grey: #e7ebf8;
  --color-secondary-20: #fef7e9;
  --color-secondary-30: #ffe3ae;
  --color-secondary-90: #fef0e7;
  --color-secondary-40: #ffd947;
  --color-secondary-50: #f7ab20;
  --color-secondary-60: #ac660c;
  --color-secondary-70: #7f4b10;
  --color-secondary-80: #401d0c;
  --color-secondary-brown-90: #ac660c;
  --color-primary-orange-brown: #c96321;
  --color-secondary-inverse: #401d0c;
  --color-secondary-background: #ffffff;
  --color-secondary-grey-100: #141414;
  --color-secondary-grey-80: rgba(0, 0, 0, 0.65);
  --color-secondary-grey-40: #a9a5a5;
  --color-secondary-grey-60: #d3d1d1;
  --color-secondary-light-gray: #d5d5d5;
  --color-secondary-default-grey: #9999ff33;
  --color-secondary-grey-45: #e0e0e0;
  --color-light-grey: #e1dede;
  --color-secondary-grey-20: #f5f5f5;
  --color-light-reliance-60: #0a2885;
  --color-vivid-light-blue: #00a1e1;
  --color-vivid-light-green: #00b259;
  --color-background-light-green: #e8faf7;
  --color-font-dark-green: #1e7b74;
  --color-light-blue-40: #14a8e3;
  --color-resq-blue: #063380;
  --color-resq-alert: #ff4d4d;
  --color-primary-red: #ff0000;
  --color-red-feedback: #660014;
  --color-resq-disabled: #858585;
  --color-resq-disabled-20: #dee2e6;
  --color-primary-success: #19c45f;
  --color-primary-text: #383838;
  --color-coral-red: #f44336;
  --color-legit-grey-45: rgba(206, 217, 224, 0.5);
  --color-legit-secondary-black-25: #000000a6;
  --color-light-crimson-80: #2a0009;
  --color-header-menu-hover: #e6e6e6;
  --color-radio-shadow-primary: rgba(16, 22, 26, 0.2);
  --color-radio-shadow-secondary: rgba(16, 22, 26, 0.1);
  --color-radio-outline: rgba(19, 124, 189, 0.6);
  --color-radio-hover: #ebf1f5;
  --color-radio-input: rgba(206, 217, 224, 0.5);
  --color-root-ele: rgba(0, 0, 0, 0.4);
  --color-dropdown-box-shadow: rgb(0 0 0 / 16%);
  --color-light-red: #fee6ea;
  --color-light-sky-blue: #6789f4;
  --color-light-crimson: #d4d4d4;
}
