.avatar {
  &__container {
    padding: 12px;
  }
  &__image__container {
    height: 40px;
    width: 40px;
    border-radius: 50%;
  }
}
